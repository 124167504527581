import * as React from 'react';
import { isEqual } from 'lodash';


type Props = {
  className?: string;
};

function Copyright({ className }: Props) {
  const currentYear = (new Date()).getFullYear();
  return (
    <div className={className}>
      <span>{`© 2017-${currentYear} Rebel Thorp • All rights reserved`}</span>
    </div>
  );
}

export default React.memo(Copyright, ((prev, next) => {
  return isEqual(prev.className, next.className);
})) as typeof Copyright;
