import * as React from 'react';
import { clsx } from 'clsx';

type Props = {
  id?: string;
  type?: 'v2' | 'v3';
}

export default function GoogleReCaptcha({ id, type = 'v2' }: Props) {
  return (
    <div id={id} className={clsx({ 'grecaptcha-widget': type === 'v2' })}/>
  );
}
