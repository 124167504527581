import * as React from 'react';
import { clsx } from 'clsx';
import * as styles from './HambrgerButton.module.scss';


type Props = {
  className?: string;
  isActive: boolean;
  onClick: React.MouseEventHandler;
};

export default function HamburgerButton({ className, isActive, onClick }: Props) {
  return (
    <button
      type="button"
      className={clsx(styles.button, className, {[styles.active]: isActive })}
      onClick={onClick}
    >
      <span/>
      <span/>
      <span/>
    </button>
  );
}
