// noinspection JSIgnoredPromiseFromCall

import * as React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useRecoilValueLoadable } from 'recoil';
import { isUndefined } from 'lodash';
import { isAuthenticatedSelector } from '@core/atoms';
import { ACCESS } from '@core/constants';
import { AccessType } from '@interface/common';


const PRODUCTS_BASE: string = '/products';
const CHECKOUT_BASE: string = '/checkout';
const ACCOUNT_BASE: string = '/account';

export const pages = {
  HOME: '/',
  PRODUCTS: PRODUCTS_BASE,
  BUNDLES: '/bundles',
  GUIDES: '/guides/animation',
  FAQ: '/faq',
  CART: '/cart',
  BILLING: '/billing',
  CONFIRM_ORDER: '/confirm',
  CHECKOUT_SUCCESS: '/success',
  CHECKOUT_FAILURE: '/failure',
  TERMS: '/terms-of-use',
  EULA: '/eula',
  PRIVACY: '/privacy-policy',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_EMAIL: '/confirm-email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACCOUNT: ACCOUNT_BASE,
  ORDERS_HISTORY: '/purchases',
  PROFILE: '/profile',
  SECURITY: '/security',
  NEWSLETTER: '/newsletter',
};

export const routePath = (path: string): string => {
  if ([
    pages.BUNDLES,
  ].includes(path)) {
    return PRODUCTS_BASE + path;

  } else if ([
    pages.CONFIRM_EMAIL,
    pages.RESET_PASSWORD,
    pages.ORDERS_HISTORY,
    pages.PROFILE,
    pages.SECURITY,
    pages.NEWSLETTER,
  ].includes(path)) {
    return ACCOUNT_BASE + path;

  } else if ([
    pages.BILLING,
    pages.CONFIRM_ORDER,
    pages.CHECKOUT_SUCCESS,
    pages.CHECKOUT_FAILURE,
  ].includes(path)) {
    return CHECKOUT_BASE + path;
  }

  return path;
};

const cleanPath = (path: string): string => {
  return path.replace('/', '');
}

const checkoutRoutes = (): string[] => {
  return [
    pages.BILLING,
    pages.CONFIRM_ORDER,
  ].map(routePath);
};

const checkoutResultRoutes = (): string[] => {
  return [
    pages.CHECKOUT_SUCCESS,
    pages.CHECKOUT_FAILURE,
  ].map(routePath);
};

const authRoutes = (): string[] => {
  return [
    pages.LOGIN,
    pages.SIGNUP,
    pages.FORGOT_PASSWORD,
  ].map(routePath);
};

const privateRoutes = (): string[] => {
  return [
    pages.ACCOUNT,
    pages.ORDERS_HISTORY,
    pages.PROFILE,
    pages.SECURITY,
    pages.NEWSLETTER,
  ].map(routePath);
};

export function useRoutes() {
  return React.useMemo(() => {
    return {
      ...pages,
      _CHECKOUT_BASE: CHECKOUT_BASE,
      _ACCOUNT_BASE: ACCOUNT_BASE,
      routes: {
        checkout: checkoutRoutes(),
        checkoutResult: checkoutResultRoutes(),
        auth: authRoutes(),
        private: privateRoutes(),
      },
      route: routePath,
      clean: cleanPath,
    };
  }, []);
}

export function useAccessOnly(access: AccessType, path?: string, condition: boolean = true) {
  const location = useLocation();
  const isAuthenticated = useRecoilValueLoadable(isAuthenticatedSelector);
  const { route, LOGIN, ACCOUNT } = useRoutes();

  function redirectToLogin() {
    if (!condition) return;

    if (isUndefined(path)) {
      navigate(route(LOGIN), { replace: true, state: { source: location!.pathname } });
    } else {
      navigate(path, { replace: true });
    }
  }

  function redirectToAccount() {
    if (!condition) return;

    navigate(isUndefined(path) ? route(ACCOUNT) : path, { replace: true });
  }

  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    switch (access) {
      case ACCESS.AUTHENTICATED:
        timer = setTimeout(() => {
          if (
            isAuthenticated.state === 'hasValue' && !isAuthenticated.contents ||
            isAuthenticated.state === 'hasError' && isAuthenticated.contents.code === 401
          ) {
            redirectToLogin();
          }
        }, 100);
        break;

      case ACCESS.UNAUTHENTICATED:
        if (isAuthenticated.state === 'hasValue' && isAuthenticated.contents) {
          redirectToAccount();
        }
        break;
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [condition, path, isAuthenticated.state, location.pathname]);
}
