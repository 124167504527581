// noinspection JSIgnoredPromiseFromCall

import { atom, selector, selectorFamily, waitForNone, AtomEffect, DefaultValue } from 'recoil';
import { ALERT } from '@core/constants';
import { isBrowser } from '@core/utils/env';
import * as api from '@core/api';
import { State } from '@interface/common';


function prefix(key: string) {
  return `rebelthorp:${key}`;
}

const localStorageEffect = (key: string): AtomEffect<any> => ({ setSelf, onSet }) => {
  if (!isBrowser) return;
  const name = prefix(key);
  const savedValue = localStorage.getItem(name);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }
  onSet((newValue: any, _oldValue: any, isReset: boolean) => {
    if (isReset) {
      localStorage.removeItem(name);
    } else {
      localStorage.setItem(name, JSON.stringify(newValue));
    }
  });
};

export const countDownAtom = atom({
  key: '$countDownAtom',
  default: {
    timeout: 0,
    expireAt: null,
  },
  effects_UNSTABLE: [
    localStorageEffect('timeout'),
  ],
});

export const alertStateDefaults: State.Alert = {
  isShown: false,
  type: ALERT.TYPE.ALERT,
  title: null,
  message: null,
  buttons: {
    accept: {
      label: 'Ok',
      type: ALERT.CONTROL.ACCENT,
    },
    cancel: {
      label: 'Cancel',
      type: ALERT.CONTROL.DEFAULT,
    },
  },
  onAccept: () => {
  },
  onCancel: () => {
  },
};
export const alertAtom = atom<State.Alert>({
  key: '$alertAtom',
  default: alertStateDefaults,
});


export const hasAccountAtom = atom({
  key: '$hasAccountAtom',
  default: false,
  effects_UNSTABLE: [
    localStorageEffect('user'),
  ],
});

export const accountQuery = selector({
  key: '$accountQuery',
  get: async ({ get }) => {
    const hasAccount = get(hasAccountAtom);
    if (hasAccount) {
      try {
        return await api.authenticate();
      } catch (_error) {
        return null;
      }
    }
    return null;
  },
});

export const accountAtom = atom({
  key: '$accountAtom',
  default: selector({
    key: '$accountAtom/default',
    get: ({ get }) => {
      return get(accountQuery);
    },
  }),
  effects_UNSTABLE: [
    ({ onSet }) => {
      if (!isBrowser) return;

      onSet((_newValue: any, _oldValue: any, isReset: boolean) => {
        if (!isReset) {
          localStorage.removeItem(prefix('cart'));
        }
      });
    },
  ],
});

export const accountSelector = selector<any>({
  key: '$accountSelector',
  get: ({ get }) => get(accountAtom),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // Order matters
      reset(accountAtom);
      reset(hasAccountAtom);
      reset(cartAtom);
      reset(libraryAtom);
      reset(proposalsAtom);
      reset(updatesAtom);
      reset(ordersCursorAtom);
      reset(countDownAtom);
    } else if (newValue !== null) {
      set(hasAccountAtom, true);
      set(accountAtom, newValue);
      set(cartAtom, newValue.cart);
    }
  },
});

export const userSelector = selector({
  key: '$userSelector',
  get: ({ get }) => {
    const account = get(accountAtom);
    return account?.user;
  },
  set: ({ set }, newValue) => {
    set(accountAtom, (prevValue) => {
      return prevValue ? { ...prevValue, user: { ...prevValue.user, ...newValue } } : prevValue;
    });
  },
});

export const isAuthenticatingAtom = atom<boolean>({
  key: '$isAuthenticatingAtom',
  default: false,
});

export const isAuthenticatedSelector = selector({
  key: '$isAuthenticatedSelector',
  get: ({ get }) => {
    const user = get(userSelector);
    return !!user?.email;
  },
});

export const purchasesSelector = selector({
  key: '$purchasesSelector',
  get: ({ get }) => {
    const isAuthenticated = get(isAuthenticatedSelector);
    if (isAuthenticated) {
      const result = get(accountAtom);
      return result?.purchases || [];
    } else {
      return [];
    }
  },
  set: ({ set }, newValue) => {
    // @ts-ignore
    set(accountAtom, (prevValue) => ({ ...prevValue, purchases: newValue }));
  },
});

export const isPurchasedSelectorFamily = selectorFamily({
  key: '$isPurchasedSelectorFamily',
  get: (productId: string) => ({ get }) => {
    const purchases = get(purchasesSelector);
    return purchases.includes(productId);
  },
});

export const proposalsAtom = atom({
  key: '$proposalsAtom',
  default: {
    ids: [],
    modal: false,
  },
  effects_UNSTABLE: [
    localStorageEffect('proposals'),
  ],
});

export const ordersQuery = selectorFamily({
  key: '$ordersQuery',
  get: (cursor: number) => async () => {
    return await api.fetchOrders(cursor + 1);
  },
});

export const ordersCursorAtom = atom<number>({
  key: '$ordersCursorAtom',
  default: 0,
});

export const ordersSelector = selector({
  key: '$ordersSelector',
  get: ({ get }) => {
    const cursor = get(ordersCursorAtom);
    const cursorRange = Array.from(Array(cursor).keys());
    const pages = get(waitForNone(cursorRange.map(ordersQuery)));
    const {
      currentCount,
      totalCount,
    } = pages.reduce((acc: { currentCount: number, totalCount: number | null }, page) => {
      acc.currentCount = page.state === 'hasValue'
        ? acc.currentCount + page.contents.orders.length
        : acc.currentCount;
      acc.totalCount = page.state === 'hasValue'
        ? page.contents.totalCount
        : acc.totalCount;
      return acc;
    }, { currentCount: 0, totalCount: null });
    return { pages, cursor, currentCount, totalCount };
  },
});

export const libraryQuery = selector({
  key: '$libraryQuery',
  get: async () => {
    return await api.fetchLibrary();
  },
});

export const libraryAtom = atom({
  key: '$libraryAtom',
  default: selector({
    key: '$libraryAtom/default',
    get: ({ get }) => {
      const isAuthenticated = get(isAuthenticatedSelector);
      if (isAuthenticated) {
        const result = get(libraryQuery);
        return result?.items || [];
      } else {
        return [];
      }
    },
  }),
});

export const latestReleaseQuery = selectorFamily({
  key: '$latestReleaseQuery',
  get: (productId: string) => async ({ get }) => {
    const isAuthenticated = get(isAuthenticatedSelector);
    const isPurchased = get(isPurchasedSelectorFamily(productId));
    if (isAuthenticated && isPurchased) {
      const result = await api.fetchLatestRelease(productId);
      return result.latestRelease;
    } else {
      return null;
    }
  },
});

export const updatesAtom = atom({
  key: '$updatesAtom',
  default: selector({
    key: '$updatesAtom/default',
    get: ({ get }) => {
      const isAuthenticated = get(isAuthenticatedSelector);
      if (isAuthenticated) {
        const library = get(libraryQuery);
        return library?.updates || [];
      } else {
        return [];
      }
    },
  }),
});

export const isUpdateAvailableSelectorFamily = selectorFamily({
  key: '$isUpdateAvailableSelectorFamily',
  get: (productId: string) => ({ get }) => {
    const updates = get(updatesAtom);
    return updates?.includes(productId) || false;
  },
});

const cartStateDefaults: State.Cart = {
  items: [],
  coupon: null,
};

export const cartAtom = atom<any>({
  key: '$cartAtom',
  default: cartStateDefaults,
  effects_UNSTABLE: [
    ({ onSet, setSelf, getPromise }) => {
      if (!isBrowser) return;
      const name = prefix('cart');

      setSelf(getPromise(accountAtom).then((account) => {
        if (account) {
          setSelf(account.cart);
        } else {
          const savedValue = localStorage.getItem(name);
          if (savedValue != null) {
            const json = JSON.parse(savedValue);
            if (json.products && json.bundles) {
              localStorage.removeItem(name);
              setSelf(cartStateDefaults);
            } else {
              setSelf(json);
            }
          } else {
            setSelf(cartStateDefaults);
          }
        }
      }));

      onSet((newValue: any, _oldValue: any, isReset: boolean) => {
        getPromise(isAuthenticatedSelector).then((isAuthenticated) => {
          if (!isAuthenticated) {
            if (isReset) {
              localStorage.removeItem(name);
            } else {
              localStorage.setItem(name, JSON.stringify(newValue));
            }
          }
        });
      });
    },
  ],
});

export const cartItemsSelector = selector<any>({
  key: '$cartItemsSelector',
  get: ({ get }) => {
    const cart = get(cartAtom);
    return cart?.items || [];
  },
  set: ({ set }, newValue) => {
    set(cartAtom, (prevValue) => ({ ...prevValue, items: newValue }));
  },
});

export const cartItemsCountSelector = selector<any>({
  key: '$cartItemsCountSelector',
  get: ({ get }) => {
    const cartItems = get(cartItemsSelector);
    return cartItems?.length || 0;
  },
});

export const couponSelector = selector<any>({
  key: '$couponSelector',
  get: ({ get }) => {
    const cart = get(cartAtom);
    return cart?.coupon;
  },
  set: ({ set }, newValue) => {
    set(cartAtom, (prevValue) => ({ ...prevValue, coupon: newValue }));
  },
});

export const isInCartSelectorFamily = selectorFamily<any, any>({
  key: '$isInCartSelectorFamily',
  get: ({ type, id }) => ({ get }) => {
    const cartItems = get(cartItemsSelector);
    return !!cartItems?.find((x: any) => x.type === type && x.id === id);
  },
});

export const isInBundleSelectorFamily = selectorFamily<any, any>({
  key: '$isInBundleSelectorFamily',
  get: (id: string) => ({ get }) => {
    const cartItems = get(cartItemsSelector);
    return !!cartItems?.filter((x: any) => x.type === 'bundle').some((x: any) => x.products?.includes(id))
  },
});
