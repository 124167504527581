import * as React from 'react';
import Modal from '../../../src/components/Modal';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import { WindowLocation } from '@reach/router';
import ModalRoutingContext from './ModalRoutingContext';


const withoutPrefix = (path) => {
  // @ts-ignore
  const prefix = typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__;

  return path.slice(prefix ? prefix.length : 0);
};

type Props = {
  location: WindowLocation;
  pageResources: any;
  modalProps: any;
  onModalChange: (isActive: boolean) => void;
}

class WrapPageElement extends React.Component<Props> {
  state = {
    prevProps: null,
    lastModalProps: null,
    props: null,
    pathname: null,
  };

  modalContentRef = null;

  constructor(args) {
    super(args);
  }

  static getDerivedStateFromProps(props, state) {
    // TODO: handle history changes
    if (props.location.pathname !== state.pathname) {
      return {
        pathname: props.location.pathname,
        props: props,
        ...(get(state, 'props.location.state.modal') ? {
          // old page was a modal, keep track, so we can render the contents while closing
          lastModalProps: state.props,
        } : {
          // old page was not a modal, keep track, so we can render the contents under modals
          prevProps: state.props,
        }),
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'location.pathname') !== get(this.props, 'location.pathname')
      && get(this.props, 'location.state.modal')
      && this.modalContentRef
    ) {
      this.modalContentRef.scrollTop = 0;
    }

    if (get(prevProps, 'location.pathname') !== get(this.props, 'location.pathname')) {
      this.props.onModalChange(!!(prevProps && get(this.props, 'location.state.modal')));
    }
  }

  handleRequestClose = async () => {
    await navigate(
      withoutPrefix(this.state.prevProps.location.pathname),
      {
        state: {
          noScroll: true,
        },
      },
    );
  };

  render() {
    // render modal if props location has modal
    const { pageResources, location, modalProps } = this.props;
    const { prevProps, lastModalProps } = this.state;
    const isModal = prevProps && get(location, 'state.modal');

    // the page is the previous path if this is a modal, otherwise it's the current path
    const pageElement = isModal ? (
      React.createElement(prevProps.pageResources.component.default, {
        ...prevProps,
        key: prevProps.pageResources.page.path,
      })
    ) : (
      React.createElement(pageResources.component.default, {
        ...this.props,
        key: pageResources.page.path,
      })
    );

    let modalElement = null;

    if (isModal) {
      // Rendering the current page as a modal, so create an element with the page contents
      modalElement = React.createElement(pageResources.component.default, {
        ...this.props,
        key: pageResources.page.path,
      });
    } else if (lastModalProps) {
      // Not rendering the current page as a modal, but we may be in the process of animating
      // the old modal content to close, so render the last modal content we have cached

      modalElement = React.createElement(get(lastModalProps, 'pageResources.component.default'), {
        ...lastModalProps,
        key: get(lastModalProps, 'pageResources.page.path'),
      });
    }

    return (
      <>
        {pageElement}

        <Modal
          onRequestClose={this.handleRequestClose}
          contentRef={node => this.modalContentRef = node}
          {...modalProps}
          isOpen={!!isModal}
        >
          <ModalRoutingContext.Provider
            value={{
              modal: !!modalElement,
              closeTo: prevProps ? withoutPrefix(prevProps.location.pathname) : '/',
            }}
          >
            {modalElement}
          </ModalRoutingContext.Provider>
        </Modal>
      </>
    );
  }
}

const wrapPageElement = ({ props }, opts) => {
  const { modalProps } = opts;
  return React.createElement(WrapPageElement, { ...props, modalProps });
};

export default wrapPageElement;
