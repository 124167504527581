import * as React from 'react';
// import { isEqual } from 'lodash';
import { ListItemProps } from '@interface/common';


export default function ArrayList<Type>({
    items = [],
    render,
  }: {
    items?: Array<Type>,
    render: string | React.FunctionComponent<ListItemProps<Type>> | React.ComponentClass<ListItemProps<Type>, any>
  },
) {
  const componentId = React.useId();
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={`${componentId}-${index}`}>
          {React.createElement(render, { item, index })}
        </React.Fragment>
      ))}
    </>
  );
}

// export default React.memo(
//   ArrayList,
//   ((prevProps, nextProps) => {
//     return isEqual(prevProps.items, nextProps.items);
//   }),
// ) as typeof ArrayList;
