// extracted by mini-css-extract-plugin
export var active = "dc944";
export var content = "_46811";
export var content__centerer = "ef460";
export var content__inner = "_51f65";
export var legal = "eaabd";
export var nav = "_2020d";
export var nav__child = "_4e5ec";
export var offcanvas = "_32498";
export var overlay = "_4e168";
export var title = "_5966a";
export var visible = "_1c819";
export var widget = "_1a298";