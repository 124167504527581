import axios from 'axios';
import { stringify } from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { SERVER_STATUS } from './constants';
import { isBrowser } from '@core/utils/env';
import { AnyObject, API } from '@interface/common';


const api = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: 'brackets' }),
  },
});

api.interceptors.request.use(
  (config) => ({ ...config, data: decamelizeKeys(config.data) }),
);

api.interceptors.response.use(
  (response) => ({ ...response, data: camelizeKeys(response.data) }),
  (error) => {
    let output;
    if (error.response?.data) {
      if (error.response.status === SERVER_STATUS.INTERNAL_SERVER_ERROR.code) {
        output = SERVER_STATUS.INTERNAL_SERVER_ERROR;
      } else {
        output = camelizeKeys(error.response.data);
      }
    } else {
      output = { code: error.code, message: error.message };
    }
    return Promise.reject(output);
  },
);

async function request(url: string, data: AnyObject) {
  if (!isBrowser) return;

  const response = await api.post(url, data);
  return response.data;
}

export const subscribeNewsletter: API.SubscribeNewsletter.Request = async (email, captcha) => {
  return request('/subscribe-newsletter', { email, captcha });
};

export const authWithProvider: API.AuthWithProvider.Request = async (provider, payload) => {
  return request(`/auth/${provider}`, payload);
};

export const signUp: API.SignUp.Request = async (user, captcha, cart) => {
  return request('/signup', { user, captcha, cart });
};

export const logIn: API.LogIn.Request = async (user, cart) => {
  return request('/login', { user, cart });
};

export const requestPasswordReset: API.RequestPasswordReset.Request = async (user, captcha) => {
  return request('/request-password-reset', { user, captcha });
};

export const resetPassword: API.ResetPassword.Request = async (user, token, cart) => {
  return request('/reset-password', { user, token, cart });
};

export const validatePasswordResetToken: API.ValidatePasswordResetToken.Request = async (token) => {
  return request('/validate-password-reset-token', { token });
};

export const confirmEmail: API.ConfirmEmail.Request = async (token) => {
  return request('/confirm-email', { token });
};

export const authenticate: API.Authenticate.Request = async () => {
  return request('/authenticate', {});
};

export const logOut: API.LogOut.Request = async () => {
  return request('/logout', {});
};

export const resendEmailConfirmation: API.ResendEmailConfirmation.Request = async () => {
  return request('/resend-email-confirmation', {});
};

export const updateProfile: API.UpdateProfile.Request = async (user) => {
  return request('/update-profile', { user });
};

export const updateNewsletterPrefs: API.UpdateNewsletterPrefs.Request = async (user) => {
  return request('/update-newsletter-prefs', { user });
};

export const updatePassword: API.UpdatePassword.Request = async (user) => {
  return request('/update-password', { user });
};

export const setPassword: API.SetPassword.Request = async (user) => {
  return request('/set-password', { user });
};

export const fetchLibrary: API.FetchLibrary.Request = async () => {
  return request('/fetch-library', {});
};

export const fetchLatestRelease: API.FetchLatestRelease.Request = async (productId) => {
  return request('/fetch-latest-release', { productId });
};

export const fetchOrders: API.FetchOrders.Request = async (page = 1) => {
  return request('/fetch-orders', { page });
};

export const updateCart: API.UpdateCart.Request = async (items) => {
  return request('/update-cart', { items });
};

export const applyCoupon: API.ApplyCoupon.Request = async (code, captcha) => {
  return request('/apply-coupon', { code, captcha });
};

export const revokeCoupon: API.RevokeCoupon.Request = async (token, captcha) => {
  return request('/revoke-coupon', { token, captcha });
};

export const checkout: API.Checkout.Request = async (isGuest: boolean = false) => {
  return request('/checkout', { isGuest });
};

export const checkoutSuccess: API.CheckoutSuccess.Request = async (orderId?: string) => {
  return request('/checkout-success', { orderId });
};

export const checkoutFailure: API.CheckoutFailure.Request = async (orderId?: string) => {
  return request('/checkout-failure', { orderId });
};
