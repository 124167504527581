import * as React from 'react';
import { throttle } from 'lodash';


export default function useThrottle(handler: (...args: any[]) => any, delay = 500, deps: any[] = []) {

  const throttledHandler = React.useMemo(() => throttle(handler, delay, { leading: true }), deps);

  React.useEffect(() => {
    return () => {
      throttledHandler.cancel();
    };
  }, []);


  return throttledHandler;
};
