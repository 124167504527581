// noinspection JSIgnoredPromiseFromCall

import * as React from 'react';
import { BroadcastChannel, OnMessageHandler } from 'broadcast-channel';
import { isBrowser } from '@core/utils/env';
import { useUser } from '@hooks/user';
import { useCart } from '@hooks/cart';


declare type Message = {
  name: string;
  payload: any
};

const bc = isBrowser ? new BroadcastChannel<Message>('main_channel', { webWorkerSupport: true }) : null;

export function useBroadcastEmitter() {

  function dispatch(name: Message['name'], payload?: Message['payload']) {
    bc?.postMessage({ name, payload });
  }

  return dispatch;
}

export function useBroadcastListener() {
  const user = useUser();
  const cart = useCart();

  React.useEffect(() => {
    const handler: OnMessageHandler<Message> = ({ name, payload }) => {
      switch (name) {
        case 'signIn':
          user.signIn(payload.data, payload.isGuest);
          break;
        case 'signOut':
          user.signOut();
          break;
        case 'confirmEmail':
          user.confirmEmail();
          break;
        case 'markPasswordAsSet':
          user.markPasswordAsSet(payload.user);
          break;
        case 'updateProfile':
          user.updateProfile(payload.user);
          break;
        case 'updateSubscriptions':
          user.updateSubscriptions(payload.user);
          break;
        case 'addToPurchases':
          user.addToPurchases(payload.items);
          break;
        case 'addToCart':
          cart.addToCart(payload.type, payload.data);
          break;
        case 'removeFromCart':
          cart.removeFromCart(payload.type, payload.ids);
          break;
        case 'clearCart':
          cart.clearCart();
          break;
        case 'applyCoupon':
          cart.applyCoupon(payload.coupon);
          break;
        case 'revokeCoupon':
          cart.revokeCoupon();
          break;
        default:
          return;
      }
    };

    bc?.addEventListener('message', handler);

    return () => {
      bc?.removeEventListener('message', handler);
    };
  }, []);
}
