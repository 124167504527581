// extracted by mini-css-extract-plugin
export var footer = "_25641";
export var footer__column = "_39e51";
export var footer__copyright = "b1218";
export var footer__email = "_65b1e";
export var footer__inner = "_14c52";
export var footer__nav = "_70532";
export var footer__nav__item = "_86dfc";
export var footer__scrollButton = "_2a681";
export var footer__socials = "ddb76";
export var footer__socials__image = "ce8f8";
export var footer__socials__list = "_6a5a6";
export var footer__title = "_961af";
export var marginBottom = "fece9";