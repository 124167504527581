import * as React from 'react';

export default function useDebouncedValue<Type>(value: Type, {
    skipOnInit = false,
    delay = 500,
    callback,
  }: {
    skipOnInit?: boolean,
    delay?: number,
    callback?: (value: Type) => void
  },
): Type {
  const initial = React.useRef(true);
  const [debouncedValue, setDebouncedValue] = React.useState<Type>(value);

  React.useEffect(() => {
    if (skipOnInit && initial.current) {
      initial.current = false;
      return () => {
      };
    }

    const timer = setTimeout(() => {
      setDebouncedValue(value);
      callback?.(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
