import * as React from 'react';
import { clsx } from 'clsx';
import { useOnClickOutside } from '@core/hooks';
import AccountMenu from '@components/account/AccountMenu';
import Avatar from './Avatar';
import ArrowIcon from '@assets/arrow.svg';
import { User } from '@interface/common';
import * as styles from './LoginWidget.module.scss';


type Props = {
  user: User
  className?: string;
  onClick?: () => void;
}

export default function LoginUserWidget({ user, className, onClick }: Props) {
  const [isShown, setIsShown] = React.useState(false);
  const widgetRef = React.useRef(null);

  const handleMenuToggle = React.useCallback(() => setIsShown((state => !state)), []);

  const handleMenuHide = React.useCallback(() => {
    setIsShown(false);
  }, []);

  const handleItemClick = React.useCallback(() => {
    handleMenuHide();
    onClick?.();
  }, []);

  useOnClickOutside(widgetRef, handleMenuHide);

  return (
    <div className={clsx(styles.container, styles.container__auth, className)} ref={widgetRef}>
      <button
        className={clsx(styles.authorized, styles.trigger, { [styles.triggerActive]: isShown })}
        type="button"
        onClick={handleMenuToggle}
      >
        <Avatar/>
        <span className={styles.username}>{user?.name}</span>
        <ArrowIcon className={clsx(styles.arrow, { [styles.arrowActive]: isShown })}/>
      </button>
      {isShown && (
        <div className={styles.menu}>
          <div className={styles.menu__welcome}>
            <p className={styles.menu__caption}>Welcome,</p>
            <p className={styles.menu__name}>{user!.name}</p>
          </div>
          <AccountMenu
            activeClassName={styles.active}
            onItemClick={handleItemClick}
          />
        </div>
      )}
    </div>
  );
}