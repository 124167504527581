import * as React from 'react';
import { defaults } from 'lodash';
import { clsx } from 'clsx';
import { BREAKPOINT, SIDEBAR } from '@core/constants';
import { HorizontalPositionType, BreakpointType } from '@interface/common';
import * as styles from './Centerer.module.scss';


type CentererContextType = {
  position: HorizontalPositionType | null;
  breakpoints: BreakpointType[];
}

const defaultContext: CentererContextType = {
  position: null,
  breakpoints: SIDEBAR.DEFAULT_BREAKPOINTS,
};

export const CentererContext = React.createContext<CentererContextType>(defaultContext);


type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode | React.ReactNode[];
  tagName?: string;
  withTopBarOffset?: boolean;
  withTopPadding?: boolean;
  withVerticalPadding?: boolean;
  sidebar?: HorizontalPositionType | null;
  sidebarBreakpoints?: BreakpointType[];
}

export default function Centerer({
  children,
  className,
  tagName = 'div',
  withTopBarOffset = false,
  withVerticalPadding = false,
  sidebar,
  sidebarBreakpoints,
  ...other
}: Props) {
  const context = React.useMemo(() => {
    return defaults({
      position: sidebar,
      breakpoints: sidebarBreakpoints,
    }, defaultContext);
  }, [sidebar]);

  const props = {
    className: clsx(styles.container, className, {
      [styles.withTopBarOffset]: withTopBarOffset && !withTopBarOffset,
      [styles.withVerticalPadding]: !withTopBarOffset && withVerticalPadding,
      [styles.withTopBarOffsetAndVerticalPadding]: withTopBarOffset && withVerticalPadding,
      [styles.withSidebar]: context.position,
      [styles.tablet]: context.breakpoints.includes(BREAKPOINT.NAME.TABLET),
      [styles.desktop]: context.breakpoints.includes(BREAKPOINT.NAME.DESKTOP),
      [styles.wide]: context.breakpoints.includes(BREAKPOINT.NAME.WIDE),
      [styles.ultra]: context.breakpoints.includes(BREAKPOINT.NAME.ULTRA),
    }),
    ...other,
  };

  return (
    <CentererContext.Provider value={context}>
      {React.createElement(tagName, props, children)}
    </CentererContext.Provider>
  );
}
