export const OVERLAY = {
  BASE: 'modal__overlay',
  AFTER_OPEN: 'modal__overlay--after-open',
  BEFORE_CLOSE: 'modal__overlay--before-close',
};

export const CONTENT = {
  BASE: 'modal__content',
  AFTER_OPEN: 'modal__content--after-open',
  BEFORE_CLOSE: 'modal__content--before-close',
};

export const PORTAL: string = 'modal_portal';
export const BODY_OPEN: string = 'modal__body--open';
export const HTML_OPEN: string = 'modal__html--open';
export const INNER: string = 'modal__inner';

export const CLOSE = {
  WRAPPER: 'modal__close__wrapper',
  BUTTON: 'modal__close__button',
};
