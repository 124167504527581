import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { uniq } from 'lodash';
import { accountSelector, purchasesSelector, userSelector } from '@core/atoms';
import { useBroadcastEmitter } from '@hooks/broadcast';
import { API, User } from '@interface/common';


export function useUser() {
  const dispatch = useBroadcastEmitter();
  const setAccount = useSetRecoilState(accountSelector);
  const setUser = useSetRecoilState(userSelector);
  const resetAccount = useResetRecoilState(accountSelector);
  const setPurchases = useSetRecoilState(purchasesSelector);

  function signIn(data: API.LogIn.Response, isGuest: boolean, broadcast = false) {
    setAccount(data);
    broadcast && dispatch('signIn', { data, isGuest });
  }

  function signOut(broadcast = false) {
    resetAccount();
    broadcast && dispatch('signOut');
  }

  function confirmEmail(broadcast = false) {
    setUser((prevState: any) => ({ ...prevState, isEmailConfirmed: true }));
    broadcast && dispatch('confirmEmail');
  }

  function markPasswordAsSet(user: User, broadcast = false) {
    setUser((prevState: any) => ({ ...prevState, isPasswordSet: user.isPasswordSet }));
    broadcast && dispatch('markPasswordAsSet', { user });
  }

  function updateProfile(user: User, broadcast = false) {
    setUser((prevState: any) => ({ ...prevState, ...user }));
    broadcast && dispatch('updateProfile', { user });
  }

  function updateSubscriptions(user: User, broadcast = false) {
    setUser((prevState: any) => ({ ...prevState, subscriptions: user.subscriptions }));
    broadcast && dispatch('updateSubscriptions', { user });
  }

  function addToPurchases(items: any[], broadcast = false) {
    if (!items) return;
    setPurchases((prevState: string[]) => {
      return uniq([...prevState, ...(items.map((item: any) => item.productId))]);
    });
    broadcast && dispatch('addToPurchases', { items });
  }

  return {
    signIn,
    signOut,
    confirmEmail,
    markPasswordAsSet,
    updateProfile,
    updateSubscriptions,
    addToPurchases,
  };
}
