import * as React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isUndefined } from 'lodash';
import { API } from '@interface/common';

export default function useCaptcha() {
  const widgetRef = React.useRef<number | undefined>(undefined);
  const [tokenV2, setTokenV2] = React.useState<string | undefined>(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();

  function isLowScore(error: API.Error.Response) {
    return error.code === 403 && error.message === 'Low score';
  }

  function isRendered() {
    return !!widgetRef.current;
  }

  function render(captchaId: string) {
    widgetRef.current = grecaptcha.render(captchaId, {
      sitekey: process.env.GATSBY_RECAPTCHA_V2_KEY,
      theme: 'dark',
      size: 'normal',
      callback: setTokenV2,
    });
  }

  function reset() {
    if (!isUndefined(widgetRef.current)) {
      grecaptcha.reset(widgetRef.current);
    }
  }

  return {
    tokenV2,
    execute: executeRecaptcha,
    isLowScore,
    isRendered,
    render,
    reset,
  };
};
