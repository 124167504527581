import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GoogleLogin, CredentialResponse, GsiButtonConfiguration } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { useAnalytics } from '@hooks/analytics';
import { useCart } from '@hooks/cart';
import * as api from '@core/api';
import { DEFAULT_LOCALE, LABEL, ERROR } from '@core/constants';
import BaseIdentityButton from './BaseIdentityButton';
import GoogleSVG from '@assets/google-logo.svg';
import * as styles from './GoogleIdentityButton.module.scss';
import { API } from '@interface/common';


type LabelType = 'signin' | 'signup' | 'continue';

const getLabel = (text: LabelType) => {
  switch (text) {
    case 'signin':
      return LABEL.SIGNIN;
    case 'signup':
      return LABEL.SIGNUP;
    case 'continue':
      return LABEL.CONTINUE;
  }
};

type Props = {
  text: LabelType;
  onSuccess: (response: API.AuthWithProvider.Response) => void;
  overwriteCart?: boolean;
  disabled?: boolean;
}

export default function GoogleIdentityButton({
  text,
  overwriteCart = false,
  disabled = false,
  onSuccess,
}: Props) {
  const { getCartData } = useCart();
  const tracking = useAnalytics();
  const [isHover, setIsHover] = React.useState(false);


  const handleSuccess = async (res: CredentialResponse) => {
    if (!res.clientId || !res.credential) {
      toast.error(ERROR.AUTH_WITH_PROVIDER_FAILED);
      return;
    }

    try {
      const { credential } = res;
      const cart = getCartData(overwriteCart);
      const response = await api.authWithProvider('google', { credential, cart });
      tracking.auth(response.flow === 'login' ? 'login' : 'sign_up', 'Google');
      onSuccess(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleError = React.useCallback(() => toast.error('Login Failed'), []);

  const handleMouseEnter = React.useCallback(() => setIsHover(true), []);
  const handleMouseLeave = React.useCallback(() => setIsHover(false), []);

  const googleButtonLabel = React.useMemo<GsiButtonConfiguration['text']>(() => `${text}_with`, [text]);
  const customButtonLabel = React.useMemo(() => `${getLabel(text)} with Google`, [text]);

  return (
    <div
      className={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ErrorBoundary fallback={<div>{ERROR.AUTH_WITH_PROVIDER_FAILED}</div>}>
        {!disabled && (
          <GoogleLogin
            type="standard"
            theme="filled_black"
            size="large"
            width={420}
            locale={DEFAULT_LOCALE}
            text={googleButtonLabel}
            shape="square"
            logo_alignment="left"
            onSuccess={handleSuccess}
            onError={handleError}
          />
        )}
        <BaseIdentityButton
          className={styles.fake}
          isHover={isHover}
          text={customButtonLabel}
          disabled={disabled}
        >
          <GoogleSVG className={styles.icon}/>
        </BaseIdentityButton>
      </ErrorBoundary>
    </div>
  );
}
