import { ShouldUpdateScrollArgs } from 'gatsby';
import { get } from 'lodash';

const shouldUpdateScroll = ({ routerProps: { location } }: ShouldUpdateScrollArgs) => {
  const isModal = get(location, 'state.modal');
  const preventUpdateScroll = get(location, 'state.noScroll');

  return !isModal && !preventUpdateScroll;
};

export default shouldUpdateScroll;
