import * as React from 'react';
import { clsx } from 'clsx';
import { MESSAGE } from '@core/constants';
import MessageIcon from '@components/MessageIcon';
import { MessageType } from '@interface/common';
import * as styles from './Message.module.scss';


type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: MessageType;
  children?: React.ReactNode | React.ReactNode[];
  contentClassName?: string;
  icon?: string | React.FC<{ className: any; }> | React.ComponentClass<{ className: any; }, any>;
  noMargin?: boolean;
}

export default function Message({
  children,
  className,
  contentClassName,
  type = MESSAGE.INFO,
  icon,
  noMargin = false,
  ...other
}: Props) {
  return (
    <div
      {...other}
      className={clsx('message', styles.container, className, type, { [styles.noMargin]: noMargin })}
    >
      <div className="message__icon">
        {icon ? React.createElement(icon) : <MessageIcon type={type}/>}
      </div>
      <div className={clsx('message__content', contentClassName)}>{children}</div>
    </div>
  );
}
