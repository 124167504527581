import * as React from 'react';
import { isEqual, isUndefined } from 'lodash';
import { LABEL } from '@core/constants';
import { useRoutes } from '@hooks/routes';
import NavLink from './NavLink';
import { ProductCategory } from '@interface/gatsby';


type Props = {
  className?: string;
  childClassName?: string;
  categories?: ProductCategory[];
  onClick?: React.MouseEventHandler;
};

function Nav({ className, childClassName, categories, onClick }: Props) {
  const componentId = React.useId();
  const { route, HOME, PRODUCTS, BUNDLES, GUIDES, FAQ } = useRoutes();

  return (
    <nav className={className}>
      <NavLink
        to={route(HOME)}
        label={LABEL.HOME}
        onClick={onClick}
      />
      <NavLink
        to={route(PRODUCTS)}
        label={LABEL.PRODUCTS}
        partiallyActive
        onClick={onClick}
      />
      {!isUndefined(categories) && (
        <div className={childClassName}>
          {categories.map((item, index) => (
            <React.Fragment key={`${componentId}-${index}`}>
              <NavLink
                to={item.url}
                label={item.title}
                partiallyActive
                onClick={onClick}
              />
            </React.Fragment>
          ))}
          <NavLink
            to={route(BUNDLES)}
            label={LABEL.BUNDLES}
            partiallyActive
            onClick={onClick}
          />
        </div>
      )}
      <NavLink
        to={route(GUIDES)}
        label={LABEL.GUIDES}
        partiallyActive
        onClick={onClick}
      />
      <NavLink
        to={route(FAQ)}
        label={LABEL.FAQ}
        onClick={onClick}
      />
    </nav>
  );
}

export default React.memo(Nav, ((prev, next) => {
  return isEqual(prev.className, next.className) &&
    isEqual(prev.childClassName, next.childClassName) &&
    isEqual(prev.categories, next.categories);
})) as typeof Nav;
