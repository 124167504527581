// extracted by mini-css-extract-plugin
export var active = "_91b03";
export var arrow = "_5e04d";
export var arrowActive = "_2a62b";
export var authorized = "_740c2";
export var button = "_9b1b6";
export var button__icon = "_413da";
export var container = "_3f579";
export var container__auth = "_943cb";
export var container__loading = "_8f8f7";
export var menu = "_6b465";
export var menu__caption = "_8e70b";
export var menu__name = "_14e3b";
export var menu__welcome = "e0d0d";
export var trigger = "bce36";
export var triggerActive = "_31ac8";
export var unauthorized = "_8afc0";
export var username = "_47d19";