import * as React from 'react';
import { startCase } from 'lodash';
import { SOCIAL } from '@core/constants';
import ArrayList from '@components/ArrayList';
import Link from '@components/Link';
import ArtStationSVG from '@assets/socials/artstation.svg';
import FacebookSVG from '@assets/socials/facebook.svg';
import InstagramSVG from '@assets/socials/instagram.svg';
import MarketplaceSVG from '@assets/socials/marketplace.svg';
import TwitterSVG from '@assets/socials/twitter-x.svg';
import YouTubeSVG from '@assets/socials/youtube.svg';
import { Social, SocialType } from '@interface/gatsby';


type SocialIconProps = {
  type: SocialType;
  width: number,
  height: number,
};

const SocialIcon = ({ type, ...other }: SocialIconProps) => {
  switch (type) {
    case SOCIAL.ARTSTATION:
      return <ArtStationSVG {...other}/>;
    case SOCIAL.FACEBOOK:
      return <FacebookSVG {...other}/>;
    case SOCIAL.INSTAGRAM:
      return <InstagramSVG {...other}/>;
    case SOCIAL.MARKETPLACE:
      return <MarketplaceSVG {...other}/>;
    case SOCIAL.TWITTER:
      return <TwitterSVG {...other}/>;
    case SOCIAL.YOUTUBE:
      return <YouTubeSVG {...other}/>;
    default:
      return null;
  }
};

type SocialsProps = {
  items: Social[];
  className?: string;
};

export default function Socials({ items, className }: SocialsProps) {
  return (
    <div className={className}>
      <ArrayList<Social>
        items={items}
        render={({ item }) => (
          <Link
            to={item.url}
            title={startCase(item.type)}
            external
          >
            <SocialIcon
              type={item.type}
              width={32}
              height={32}
            />
          </Link>
        )}
      />
    </div>
  );
}
