import * as React from 'react';
import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { Link } from '@plugins/gatsby-plugin-modal-routing';
import { LABEL } from '@core/constants';
import { useRoutes } from '@hooks/routes';
import LoginIcon from '@assets/log-in.svg';
import * as styles from './LoginWidget.module.scss';


type Props = {
  className?: string;
  layout?: string;
  onClick?: () => void;
}

export default function LoginGuestWidget({ className, layout, onClick }: Props) {
  const location = useLocation();
  const { route, routes, LOGIN, SIGNUP } = useRoutes();

  const isNotAuthPath = React.useMemo(() => !routes.auth.includes(location.pathname), [location.pathname]);

  const handleSignInLinkClick = React.useCallback((event: React.SyntheticEvent) => {
    if (location.pathname === route(LOGIN)) {
      event.preventDefault();
    }
    onClick?.();
  }, [location]);

  const handleSignUpLinkClick = React.useCallback((event: React.SyntheticEvent) => {
    if (location.pathname === route(SIGNUP)) {
      event.preventDefault();
    }
    onClick?.();
  }, [location]);

  const linkState = {
    noScroll: true,
    layout,
  };
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.unauthorized}>
        <Link
          to={route(SIGNUP)}
          asModal={isNotAuthPath}
          onClick={handleSignUpLinkClick}
          state={linkState}
          data-text={LABEL.SIGNUP}
        >
          {LABEL.SIGNUP}
        </Link>
        <Link
          to={route(LOGIN)}
          className={clsx('button small light', styles.button)}
          asModal={isNotAuthPath}
          onClick={handleSignInLinkClick}
          state={linkState}
        >
          <LoginIcon className={styles.button__icon}/>
          <span>{LABEL.LOGIN}</span>
        </Link>
      </div>
    </div>
  );
}
