import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { startCase } from 'lodash';
import { clsx } from 'clsx';
import { useHtmlDataset } from '@core/hooks';
import Centerer from '@components/Centerer';
import ArrayList from '@components/ArrayList';
import Link from '@components/Link';
import { FilterListItem } from '@interface/common';
import * as styles from './Filter.module.scss';


type Props = {
  items: FilterListItem[];
  isSticky?: boolean;
  stickyTopOffset?: number;
  align?: 'left' | 'center' | 'right';
};

export default function Filter({ items, isSticky = false, stickyTopOffset = 54, align = 'left' }: Props) {
  const componentId = React.useId();
  const [isSticked, setIsStick] = React.useState(false);

  useHtmlDataset('offset', 'filter');

  const handleOnPositionChange = ({ currentPosition }: Waypoint.CallbackArgs) => {
    setIsStick(currentPosition === Waypoint.above);
  };

  return (
    <>
      {isSticky && (
        <Waypoint
          key={componentId}
          topOffset={stickyTopOffset}
          onPositionChange={handleOnPositionChange}
        />
      )}
      <section id={componentId} className={clsx(styles.container, { [styles.sticky]: isSticked })}>
        <Centerer className={clsx(styles.centerer, `text__align--${align}`)}>
          <ArrayList<FilterListItem>
            items={items}
            render={({ item }) => item === null ?
              <span className={styles.separator}>|</span> :
              <Link to={item.url} activeClassName={styles.active}>
                {startCase(item.title!)}
              </Link>
            }
          />
        </Centerer>
      </section>
      {isSticky && (
        <section className={clsx({ [styles.placeholder]: isSticked })}/>
      )}
    </>
  );
}
