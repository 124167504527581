import * as CLASSNAME from './classname';
import * as EVENT from './event';

const TIMEOUT: number = 400;

export {
  TIMEOUT,
  CLASSNAME,
  EVENT,
};
