import { addCssCustomProperty } from '@core/utils';
import useThrottle from './useThrottle';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
import useEventListener from './useEventListener';

let vw: number;
let vh: number;

export default function useCustomViewportHeightUnits() {

  const calculateViewportHeight = () => {
    let temp_vw = window.innerWidth * 0.01;
    let temp_vh = window.innerHeight * 0.01;
    if (temp_vw !== vw && temp_vh !== vh) {
      vw = temp_vw;
      vh = temp_vh;
      addCssCustomProperty('--vh', `${vh}px`);
    }
  };

  useIsomorphicLayoutEffect(() => {
    let timer = setTimeout(() => {
      calculateViewportHeight();
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const throttledResizeHandler = useThrottle(calculateViewportHeight);

  useEventListener('resize', throttledResizeHandler);
};
