import * as React from 'react';
// @ts-ignore
import ReactModal, { Props as ModalProps } from 'react-modal';
import { clsx } from 'clsx';
import { LABEL, MODAL } from '@core/constants';
import PlusIcon from '@assets/plus.svg';
import { useLocation } from '@reach/router';


ReactModal.setAppElement('#___gatsby');

const MODAL_ID = 'modal';

export type Props = ModalProps & {
  className?: string;
  useCloseButton?: boolean;
};

export default function Modal({
  children,
  isOpen,
  className,
  onRequestClose,
  useCloseButton = true,
  ...other
}: Props) {
  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();
  const location = useLocation();
  const [htmlClassName, setHtmlClassName] = React.useState<string | undefined>();

  const dispatchCustomEvent = (eventName: string) => {
    let event = new Event(eventName);
    window.dispatchEvent(event);
  };

  React.useEffect(() => {
    const modalElement = document.getElementById(MODAL_ID);
    if (modalElement) {
      modalElement.focus();
    }

    return () => clearTimeout(timerRef.current);
  }, []);

  React.useEffect(() => {
    const htmlElement = document.querySelector('html');
    if (htmlElement?.classList.contains(MODAL.CLASSNAME.HTML_OPEN)) {
      setHtmlClassName(htmlElement?.classList.value);
    } else {
      setHtmlClassName(clsx(htmlElement?.classList.value, MODAL.CLASSNAME.HTML_OPEN));
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const htmlElement = document.querySelector('html');
    if (isOpen) {
      dispatchCustomEvent(MODAL.EVENT.OPEN);
      if (htmlElement && htmlClassName) {
        htmlElement.className = htmlClassName;
      }
    } else {
      timerRef.current = setTimeout(() => {
        dispatchCustomEvent(MODAL.EVENT.CLOSE);
        htmlElement?.classList.remove(MODAL.CLASSNAME.HTML_OPEN);

      }, MODAL.TIMEOUT + 10);
    }
  }, [isOpen]);

  return (
    <ReactModal
      {...other}
      id={MODAL_ID}
      isOpen={isOpen}
      closeTimeoutMS={MODAL.TIMEOUT}
      style={{
        overlay: undefined,
        content: undefined,
      }}
      className={{
        base: clsx(MODAL.CLASSNAME.CONTENT.BASE, className),
        afterOpen: MODAL.CLASSNAME.CONTENT.AFTER_OPEN,
        beforeClose: MODAL.CLASSNAME.CONTENT.BEFORE_CLOSE,
      }}
      overlayClassName={{
        base: MODAL.CLASSNAME.OVERLAY.BASE,
        afterOpen: MODAL.CLASSNAME.OVERLAY.AFTER_OPEN,
        beforeClose: MODAL.CLASSNAME.OVERLAY.BEFORE_CLOSE,
      }}
      portalClassName={MODAL.CLASSNAME.PORTAL}
      bodyOpenClassName={MODAL.CLASSNAME.BODY_OPEN}
      htmlOpenClassName={null}
      onRequestClose={onRequestClose}
    >
      <div className={MODAL.CLASSNAME.INNER}>{children}</div>
      {useCloseButton && (
        <div className={MODAL.CLASSNAME.CLOSE.WRAPPER}>
          <button
            className={MODAL.CLASSNAME.CLOSE.BUTTON}
            type="button"
            aria-label={LABEL.CLOSE}
            onClick={onRequestClose}
          >
            <PlusIcon/>
          </button>
        </div>
      )}
    </ReactModal>
  );
}
