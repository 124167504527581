import * as React from 'react';
import FacebookLogin, {
  FacebookLoginProps,
  FailResponse,
  ProfileSuccessResponse,

} from '@greatsumini/react-facebook-login';
import { toast } from 'react-toastify';
import { useAnalytics } from '@hooks/analytics';
import { useCart } from '@hooks/cart';
import * as api from '@core/api';
import { LABEL, ERROR } from '@core/constants';
import BaseIdentityButton from './BaseIdentityButton';
import FacebookSVG from '@assets/facebook-logo.svg';
import * as styles from './FacebookIdentityButton.module.scss';
import { API } from '@interface/common';


type Props = {
  label?: string;
  onSuccess: (response: API.AuthWithProvider.Response) => void;
  overwriteCart?: boolean;
  disabled?: boolean;
}

export default function FacebookIdentityButton({
  label = LABEL.SIGNIN,
  overwriteCart = false,
  disabled = false,
  onSuccess,
}: Props) {
  const { getCartData } = useCart();
  const tracking = useAnalytics();

  const handleSignIn = React.useCallback(async (profileResponse: ProfileSuccessResponse): Promise<void> => {
    try {
      const payload = {
        user: profileResponse,
        cart: getCartData(overwriteCart),
      } as API.AuthWithProvider.FacebookPayload;

      const response = await api.authWithProvider('facebook', payload);

      tracking.auth(response.flow === 'login' ? 'login' : 'sign_up', 'Facebook');
      onSuccess(response);
    } catch (error) {
      toast.error(error.message);
    }
  }, [getCartData]);

  const handleFailure: FacebookLoginProps['onFail'] = React.useCallback((_error: FailResponse) => {
    toast.error(ERROR.AUTH_WITH_PROVIDER_FAILED);
  }, []);

  return (
    <FacebookLogin
      appId={process.env.GATSBY_FACEBOOK_APP_ID}
      initParams={{
        version: 'v13.0',
      }}
      fields="name,email"
      onFail={handleFailure}
      onProfileSuccess={handleSignIn}
      render={({ onClick }) => (
        <BaseIdentityButton
          tagName="button"
          text={`${label} with Facebook`}
          disabled={disabled}
          onClick={onClick}
        >
          <FacebookSVG className={styles.icon}/>
        </BaseIdentityButton>
      )}
    />
  );
}
