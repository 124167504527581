import * as React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import ModalRoutingContext from './ModalRoutingContext';

export default function ModalRoutingLink({
  to,
  state,
  asModal,
  ...other
}: Omit<GatsbyLinkProps<any>, 'to'> & {
  to: string | null;
  asModal?: boolean;
}) {
  return (
    <ModalRoutingContext.Consumer>
      {({ closeTo }) => (
        <Link
          // @ts-ignore
          to={to}
          state={{
            ...state,
            modal: asModal,
            noScroll: to === closeTo,
          }}
          {...other}
        />
      )}
    </ModalRoutingContext.Consumer>
  );
}
