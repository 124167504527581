import * as React from 'react';
import { clsx } from 'clsx';
import * as styles from './BaseIdentityButton.module.scss';


type Props = React.HTMLAttributes<HTMLButtonElement> & {
  tagName?: string;
  children: React.ReactNode | React.ReactNode[];
  text: string;
  isHover?: boolean;
  disabled?: boolean;
}

export default function BaseIdentityButton({
  className,
  children,
  text,
  tagName = 'div',
  isHover = false,
  disabled = false,
  ...other
}: Props) {
  const renderInner = () => (
    <>
      <span className={styles.logo}>{children}</span>
      <span className={styles.label}>{text}</span>
    </>
  );

  let props: any = {
    ...other,
    className: clsx(className, styles.button, { [styles.hover]: isHover, [styles.disabled]: disabled }),
  };

  if (tagName === 'button') {
    props.disabled = disabled;
  }

  return React.createElement(tagName, props, renderInner());
}
