import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useLocation, WindowLocation } from '@reach/router';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import { useModalOpenFix, useCustomViewportHeightUnits } from '@core/hooks';
import { useBroadcastListener } from '@hooks/broadcast';
import { useRoutes } from '@hooks/routes';
import { getSiteMetaData } from '@core/utils';
import { LAYOUT } from '@core/constants';
import { ContextProvider } from '@core/context/Context';
import Alert from '@components/Alert';
import ScrollWidthHolder from '@components/ScrollWidthHolder';
import MessageIcon from '@components/MessageIcon';
import Link from '@components/Link';
import ToastCloseButton from './parts/ToastCloseButton';
import HamburgerButton from './parts/HamburgerButton';
import Nav from './parts/Nav';
import Offcanvas from './parts/Offcanvas';
import CartWidget from './parts/CartWidget';
import LoginWidget from './parts/LoginWidget';
import Footer from './parts/Footer';
import ProductCategoryFilter from '@components/store/ProductCategoryFilter';
import LogoSVG from '@assets/logo.svg';
import { PageContext, PostgresData, ProductCategory, SiteData, SiteMetaData, LayoutType } from '@interface/gatsby';
import { MessageType } from '@interface/common';
import '../../styles.scss';
import * as styles from './index.module.scss';


type LocationState = {
  layout?: LayoutType,
}


type MainLayoutProps = {
  children: React.ReactNode,
  siteMetadata: SiteMetaData;
  categories: ProductCategory[];
  layout: PageContext['layout'];
}

function MainLayout({ children, categories, layout }: MainLayoutProps) {
  const location = useLocation() as WindowLocation<LocationState>;
  const { route, HOME } = useRoutes();
  const [isRoutingModalOpened, setIsRoutingModalOpened] = React.useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = React.useState(false);

  useModalOpenFix();
  useCustomViewportHeightUnits();
  useBroadcastListener();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        categories,
        onModalChange: setIsRoutingModalOpened,
      });
    }
    return child;
  });

  const handleOffcanvasClose = () => setIsOffcanvasOpen(false);

  const handleOffcanvasToggle = () => setIsOffcanvasOpen(prevState => !prevState);

  return (
    <>
      {(layout === LAYOUT.PRIMARY || layout === LAYOUT.CATALOG || layout === LAYOUT.CLIENT) && (
        <header
          id="header"
          className={styles.header}
        >
          <ScrollWidthHolder
            tagName="section"
            className={styles.header__inner}
          >
            <Link
              className={styles.header__logo}
              to={route(HOME)}
              onClick={handleOffcanvasClose}
              aria-label="Rebel Thorp"
            >
              <LogoSVG className={styles.logo}/>
            </Link>
            <Nav className={styles.header__nav}/>
            <div className={styles.header__widgets}>
              <CartWidget onClick={handleOffcanvasClose}/>
              <LoginWidget
                layout={layout}
                className={styles.header__login}
              />
              <HamburgerButton
                className={styles.header__hamburger}
                isActive={isOffcanvasOpen}
                onClick={handleOffcanvasToggle}
              />
            </div>
          </ScrollWidthHolder>
          {(
            layout === LAYOUT.CATALOG ||
            (isRoutingModalOpened && location.state?.layout === LAYOUT.CATALOG)
          ) && (
            <ProductCategoryFilter categories={categories}/>
          )}
        </header>
      )}
      {childrenWithProps}
      <Offcanvas
        isActive={isOffcanvasOpen}
        categories={categories}
        onRequestClose={handleOffcanvasClose}
      />
      {(layout === LAYOUT.PRIMARY || layout === LAYOUT.CATALOG) && (
        <Footer/>
      )}
      <Alert/>
      <div id="recaptcha-v3"/>
      <ToastContainer
        limit={3}
        autoClose={4000}
        icon={({ type }: { type: MessageType }) => <MessageIcon type={type}/>}
        closeButton={<ToastCloseButton/>}
        closeOnClick
        newestOnTop
      />
    </>
  );
}

type AppData = SiteData &
  PostgresData<Record<'categories', ProductCategory[]>> &
  {
    footerImage: IGatsbyImageData;
  }

type AppProps = {
  children: React.ReactNode;
  pageContext: PageContext;
}

export default function App({ children, pageContext }: AppProps) {
  const data = useStaticQuery<AppData>(graphql`
      query Socials {
          site {
              siteMetadata {
                  meta {
                      description
                      keywords
                  }
                  emails {
                      contact
                      support
                  }
                  statistics {
                      count {
                          customers
                          countries
                      }
                  }
                  socials {
                      type
                      url
                  }
              }
          }
          postgres {
              categories: productCategoriesList(orderBy: SORT_ORDER_ASC) {
                  title
                  slug
                  url
              }
          }
          footerImage: file(
              absolutePath: { glob: "**/src/images/footer.jpg" }
          ) {
              childImageSharp {
                  gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      quality: 100
                  )
              }
          }
      }
  `);
  const siteMetaData = getSiteMetaData(data);
  const categories = data.postgres.categories;

  return (
    <GoogleOAuthProvider
      clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_KEY}
        language="en"
        scriptProps={{
          defer: true,
        }}
        container={{
          element: 'recaptcha-v3',
          parameters: {
            badge: 'bottomright',
            theme: 'dark',
          },
        }}
      >
        <RecoilRoot>
          <ContextProvider
            siteMetaData={siteMetaData}
            isFloatingMenu={pageContext.floating}
            footerImage={data.footerImage}
          >
            <MainLayout
              layout={pageContext.layout}
              siteMetadata={siteMetaData}
              categories={categories}
            >
              {children}
            </MainLayout>
          </ContextProvider>
        </RecoilRoot>
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  );
}
