import * as React from 'react';
import { useForm } from 'react-final-form';
import { clsx } from 'clsx';
import BaseInput from './BaseInput';
import PlusIcon from '@assets/plus.svg';
import { FieldRenderProps } from '@interface/common';
import { BaseInputProps } from './BaseInput';
import { HTMLAttributes } from 'react';


type Props =
  FieldRenderProps<string> &
  Pick<BaseInputProps, 'useLabel' | 'fieldClassName'> &
  HTMLAttributes<HTMLInputElement> & {
  useReset?: boolean;
  hint?: string;
};

export default function TextInput({
  input,
  meta,
  id,
  label,
  hint,
  required,
  disabled,
  maxLength = 255,
  useLabel = true,
  useReset = false,
  className,
  fieldClassName,
  ...props
}: Props) {
  const componentId = id || React.useId();
  const form = useForm();

  let showReset = useReset && meta.dirty;

  const handleReset = () => {
    form.change(input.name, meta.initial);
  };

  return (
    <BaseInput
      id={componentId}
      label={label}
      meta={meta}
      required={required}
      useLabel={useLabel}
      fieldClassName={clsx(fieldClassName, { 'form__field--hasReset': showReset })}
      renderFooter={() => hint ? <div className="form__fieldHint">{hint}</div> : null}
    >
      <input
        {...input}
        {...props}
        className={clsx('form__fieldInput', className)}
        id={componentId}
        maxLength={maxLength}
        required={required}
        disabled={disabled}
      />
      <span className="form__pseudo"/>
      {showReset && (
        <button
          className="form__fieldReset"
          type="button"
          title="Reset changes"
          tabIndex={-1}
          onClick={handleReset}
        >
          <PlusIcon/>
        </button>
      )}
    </BaseInput>
  );
}
