import * as React from 'react';
import { clsx } from 'clsx';
import { isError } from '@core/forms';
import { FieldMetaState } from 'react-final-form';


export interface BaseInputProps {
  children: React.ReactNode;
  id: string;
  label?: string;
  meta: FieldMetaState<any>
  required?: boolean;
  useLabel?: boolean;
  renderSubLabel?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  fieldClassName?: string;
}

export default function BaseInput({
  children,
  label,
  id,
  required,
  meta,
  useLabel = true,
  renderSubLabel,
  renderFooter,
  fieldClassName,
}: BaseInputProps) {
  const hasError = isError(meta);
  return (
    <div className={clsx('form__field', fieldClassName, { ['form__field--hasError']: hasError })}>
      {(useLabel) && (
        <span className="form__fieldLabelWrapper">
          <label
            className={clsx('form__fieldLabel', { ['form__fieldLabel--required']: required })}
            htmlFor={id}>
            {label}
          </label>
          {renderSubLabel?.()}
        </span>
      )}
      <div className="form__fieldInner">
        {children}
        {hasError && <span className="form__fieldError">{meta.error || meta.submitError}</span>}
      </div>
      {renderFooter?.()}
    </div>
  );
}
