import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { clsx } from 'clsx';
import Context from '@core/context/Context';
import { LABEL } from '@core/constants';
import { useRoutes } from '@hooks/routes';
import { mailToURL } from '@core/utils';
import ScrollWidthHolder from '@components/ScrollWidthHolder';
import Centerer from '@components/Centerer';
import Link from '@components/Link';
import ArrowButton from '@components/ArrowButton';
import NavLink from './NavLink';
import NewsletterForm from './NewsletterForm';
import Socials from './Socials';
import Copyright from './Copyright';
import * as styles from './Footer.module.scss';


type Props = {};

export default function Footer({}: Props) {
  const { siteMetaData, footerImage, isFloatingMenu } = React.useContext(Context);
  const { route, TERMS, EULA, PRIVACY } = useRoutes();

  const image = getImage(footerImage);
  return (
    <footer
      id="footer"
      className={clsx(styles.footer, { [styles.marginBottom]: isFloatingMenu })}
    >
      <ScrollWidthHolder>
        <Centerer>
          <ArrowButton to="top" className={styles.footer__scrollButton}/>
          <div className={styles.footer__inner}>
            <div className={styles.footer__column}>
              <h4 className={styles.footer__title}>Discover</h4>
              <ul className={styles.footer__nav}>
                <li className={styles.footer__nav__item}>
                  <NavLink to={route(TERMS)} label={LABEL.TERMS}/>
                </li>
                <li className={styles.footer__nav__item}>
                  <NavLink to={route(EULA)} label="End-User License Agreement (EULA)"/>
                </li>
                <li className={styles.footer__nav__item}>
                  <NavLink to={route(PRIVACY)} label={LABEL.PRIVACY}/>
                </li>
              </ul>
            </div>
            <div className={styles.footer__column}>
              <h4 className={styles.footer__title}>Subscribe to newsletter</h4>
              <NewsletterForm/>
            </div>
            <div className={styles.footer__column}>
              <h4 className={styles.footer__title}>Contact us</h4>
              <Link
                className={clsx('underline white', styles.footer__email)}
                to={mailToURL({
                  to: siteMetaData.emails.contact,
                })}
              >
                {siteMetaData.emails.contact}
              </Link>
            </div>
            <div className={styles.footer__column}>
              <h4 className={styles.footer__title}>Product support</h4>
              <Link
                className={clsx('underline white', styles.footer__email)}
                to={mailToURL({
                  to: siteMetaData.emails.support,
                })}
              >
                {siteMetaData.emails.support}
              </Link>
            </div>
          </div>
        </Centerer>
        <div className={styles.footer__socials}>
          <GatsbyImage
            className={styles.footer__socials__image}
            image={image!}
            alt="Footer background"
          />
          <Centerer>
            <Socials
              className={styles.footer__socials__list}
              items={siteMetaData.socials}
            />
          </Centerer>
        </div>
        <Copyright className={styles.footer__copyright}/>
      </ScrollWidthHolder>
    </footer>
  );
}
