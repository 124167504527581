import * as React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { SiteMetaData } from '@interface/gatsby';


type State = {
  siteMetaData: SiteMetaData;
  isFloatingMenu: boolean;
  footerImage: IGatsbyImageData | null;
  activeCollapsible: string | null;
  setActiveCollapsible: (id: string) => void,
};

const defaultContextValue: State = {
  siteMetaData: {
    brand: '',
    siteUrl: '',
    emails: {
      contact: '',
      support: '',
    },
    meta: {},
    og: {
      siteName: '',
      twitterCreator: '',
    },
    socials: [],
    statistics: {
      count: {
        customers: 0,
        countries: 0,
      },
    },
  },
  isFloatingMenu: false,
  footerImage: null,
  activeCollapsible: null,
  setActiveCollapsible: (_id: string) => {
  },
};

const Context = React.createContext(defaultContextValue);

type Props = {
  children: React.ReactNode;
  siteMetaData: SiteMetaData;
  isFloatingMenu?: boolean;
  footerImage: IGatsbyImageData,
};

class ContextProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...defaultContextValue,
      siteMetaData: props.siteMetaData,
      isFloatingMenu: props.isFloatingMenu || false,
      footerImage: props.footerImage,
    };
  }

  setActiveCollapsible = (id: string) => {
    this.setState(state => ({
      ...state,
      activeCollapsible: id,
    }));
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, setActiveCollapsible: this.setActiveCollapsible }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export { Context as default, ContextProvider };
