export const HOME: string = `Home`;
export const PRODUCTS: string = `Products`;
export const BUNDLES: string = `Bundles`;
export const GUIDES: string = `Guides`;
export const FAQ: string = `FAQ`;
export const TERMS: string = `Terms of use`;
export const EULA: string = `EULA`;
export const PRIVACY: string = `Privacy policy`;

export const LOGIN: string = `Log in`;
export const SIGNIN: string = `Sign in`;
export const SIGNUP: string = `Sign up`;
export const FORGOT_PASSWORD: string = `Forgot password?`;

export const CONFIRM_EMAIL: string = `Confirm email`;
export const LIBRARY: string = `Library`;
export const RESET_PASSWORD: string = `Reset password`;
export const ORDERS_HISTORY: string = `Purchases`;
export const PROFILE: string = `Profile`;
export const SECURITY: string = `Security`;
export const NEWSLETTER: string = `Newsletter subscriptions`;

export const BILLING: string = `Billing`;
export const CONFIRM_ORDER: string = `Confirm order`;
export const CHECKOUT_SUCCESS: string = `Purchase complete`;
export const CHECKOUT_FAILURE: string = `Payment failed`;

export const LOADING: string = `Loading...`;
export const NOT_FOUND: string = `Not found`;
export const CONTINUE: string = `Continue`;
export const CLOSE: string = `Close`;
export const SKIP: string = `Skip`;
