import * as React from 'react';
import { clsx } from 'clsx';
import { ALERT } from '@core/constants';
import { useAlert } from '@core/hooks';
import Modal from '@components/Modal';
import * as styles from './Alert.module.scss';


export default function Alert() {
  const componentId = React.useId();
  const { isShown, type, title, message, buttons, accept, cancel, reset } = useAlert();
  const isAlert = type === ALERT.TYPE.ALERT;

  const handleRequestClose = () => isAlert && cancel();
  const handleAfterClose = () => reset();

  return (
    <Modal
      isOpen={isShown!}
      useCloseButton={isAlert}
      onRequestClose={handleRequestClose}
      onAfterClose={handleAfterClose}
    >
      <h3 className={styles.title}>{title}</h3>
      <section className={styles.message}>
        {message?.split('\n').map((paragraph, index) => (
          <p key={`${componentId}-${index}`}>{paragraph}</p>
        ))}
      </section>
      <section className={styles.controls}>
        <button
          className={clsx(styles.button, {
            [styles.button__accept]: buttons.accept?.type === ALERT.CONTROL.ACCENT,
            [styles.button__danger]: buttons.accept?.type === ALERT.CONTROL.DANGER,
          })}
          type="button"
          onClick={accept}
        >
          {buttons.accept?.label}
        </button>
        {!isAlert && (
          <button
            className={clsx(styles.button, {
              [styles.button__accept]: buttons.cancel?.type === ALERT.CONTROL.ACCENT,
              [styles.button__danger]: buttons.cancel?.type === ALERT.CONTROL.DANGER,
            })}
            type="button"
            onClick={cancel}
          >
            {buttons.cancel?.label}
          </button>
        )}
      </section>
    </Modal>
  );
}
