import * as React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { clsx } from 'clsx';
import { userSelector, hasAccountAtom } from '@core/atoms';
import { useIsClient } from '@core/hooks';
import Spinner from '@components/Spinner';
import UserWidget from './LoginUserWidget';
import GuestWidget from './LoginGuestWidget';
import * as styles from './LoginWidget.module.scss';


type Props = {
  className?: string;
  layout?: string;
  onClick?: () => void;
}

export default function LoginWidget(props: Props) {
  const isClient = useIsClient();
  const hasAccount = useRecoilValue(hasAccountAtom);
  const user = useRecoilValueLoadable(userSelector);

  if (!isClient || !hasAccount) {
    return <GuestWidget {...props}/>;
  }

  switch (user.state) {
    case 'loading':
      return (
        <div className={clsx(styles.container, styles.container__auth, styles.container__loading, props.className)}>
          <Spinner size="small"/>
        </div>
      );

    case 'hasValue':
      return user.contents
        ? <UserWidget {...props} user={user.contents}/>
        : <GuestWidget {...props}/>;

    case 'hasError':
      return <GuestWidget {...props}/>;
  }
}
