// noinspection JSIgnoredPromiseFromCall

import * as React from 'react';
import { navigate } from 'gatsby';


export default function useRedirect(path: string, condition: boolean = true) {
  React.useEffect(() => {
    if (condition) {
      navigate(path);
    }
  }, [path, condition]);
};
