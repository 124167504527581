import { LayoutType } from '@interface/gatsby';

export const PRIMARY: LayoutType = `primary`;
export const CLIENT: LayoutType = `client`;
export const CATALOG: LayoutType = `catalog`;
export const CHECKOUT: LayoutType = `checkout`;

export const TOPBAR_HEIGHT: number = 54;
export const FILTER_HEIGHT: number = 44;
export const PADDING_TOP_TABLET: number = 30;
export const PADDING_TOP_WIDE: number = 40;
