import * as React from 'react';
import { FormSpy } from 'react-final-form';
import { clsx } from 'clsx';
import Spinner from '@components/Spinner';
import * as styles from './ButtonSubmit.module.scss';
import { ButtonSizeType, ThemeType } from '@interface/common';


type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  disableOnPristine?: boolean;
  size?: ButtonSizeType;
  theme?: ThemeType;
  stretch?: boolean;
  scale?: boolean;
}

export default function ButtonSubmit({
  label,
  disableOnPristine = false,
  className,
  size = 'medium',
  theme = 'light',
  stretch = false,
  scale = false,
  disabled = false,
  ...other
}: Props) {
  return (
    <FormSpy subscription={{ submitting: true, pristine: disableOnPristine }}>
      {({ submitting, pristine }) => (
        <button
          {...other}
          type="submit"
          className={clsx('button', size, theme, styles.button, className, {
            'scale': scale,
            'stretch': stretch,
            'disabled': disabled,
            [styles.submitting]: submitting,
          })}
          disabled={disabled || submitting || (disableOnPristine && pristine)}
        >
          <Spinner size="small" className={styles.spinner}/>
          <span className={styles.label}>{label}</span>
        </button>
      )}
    </FormSpy>
  );
}
