import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { FallbackWorker } from 'fallback-worker';
import { countDownAtom } from '@core/atoms';
import { AnyObject } from '@interface/common';
import { hasLocalStorage } from '@core/utils/env';


const TIMEOUT = 60;

export default function useCountDown(subscribe = false) {
  const [countDown, setCountDown] = useRecoilState(countDownAtom);

  if (!hasLocalStorage) {
    return undefined;
  }

  function init(): FallbackWorker {
    return new FallbackWorker('/countdown.js');
  }

  function start(worker?: FallbackWorker): void {
    worker = worker || init();
    worker.postMessage(TIMEOUT);

    const now = new Date();
    const expireAt = new Date(now.getTime() + TIMEOUT * 1000);

    setCountDown({
      timeout: TIMEOUT,
      expireAt: expireAt.toString(),
    });
  }

  function restartFromCache(worker: FallbackWorker): void {
    if (countDown?.expireAt) {
      const diff: number = Date.parse(countDown.expireAt) - Date.now();
      if (diff > 0) {
        const timeout = Math.ceil(diff / 1000);
        worker.postMessage(timeout);
        setCountDown((prev: any) => ({ ...prev, timeout }));
      } else {
        // store.remove();
        setCountDown((prev: any) => ({ ...prev, expireAt: null }));
      }
    }
  }

  useEffect(() => {
    let worker: FallbackWorker;

    const handler = (timeout: number) => setCountDown((prev: any) => ({ ...prev, timeout }));

    if (subscribe) {
      worker = init();
      worker.on('message', ({ data }: AnyObject) => {
        handler(data);
      });
      restartFromCache(worker);
    }

    return () => {
      worker?.terminate();
    };
  }, []);

  const isActive = countDown.timeout > 0;

  return { start, timeout: countDown.timeout, isActive };
};
