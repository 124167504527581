import { get } from 'lodash';
import { getElementOffset, getScrollOffset } from './src/core/utils';


function jumpToPosition(position: number): void {
  // @ts-ignore
  window.scrollTo({ top: position, behavior: 'instant' });
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', {
        page_title: document.title?.split('/')[0].trim(),
        page_path: pagePath,
      });
    }
  }, 100);
};

export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location }, getSavedScrollPosition }) => {
  const isModal = get(location, 'state.modal');
  const preventUpdateScroll = get(location, 'state.noScroll');

  if (!isModal && !preventUpdateScroll) {
    const { pathname, hash } = location;

    let offset = 0;
    if (pathname.startsWith('/guides')) {
      offset = getScrollOffset(true);
    } else if (['/terms-of-use', '/privacy-policy'].includes(pathname)) {
      offset = getScrollOffset();
    }

    if (prevRouterProps) {
      const {
        location: { pathname: oldPathname },
      } = prevRouterProps;

      if (oldPathname !== pathname) {
        if (hash) {
          const elementOffset = getElementOffset(hash, offset);
          jumpToPosition(elementOffset);
        } else {
          const currentPosition = getSavedScrollPosition(location);
          jumpToPosition(currentPosition ? currentPosition[1] : 0);
        }

        return false;
      }
    }
  } else {
    return false;
  }
};
