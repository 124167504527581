// noinspection JSIgnoredPromiseFromCall

import * as React from 'react';
import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { difference, isArray, isUndefined } from 'lodash';
import {
  proposalsAtom,
  cartItemsSelector,
  cartAtom,
  couponSelector,
  isAuthenticatedSelector,
  purchasesSelector,
} from '@core/atoms';
import { ITEM } from '@core/constants/cart';
import { useCaptcha } from '@core/hooks';
import { useBroadcastEmitter } from '@hooks/broadcast';
import { useAnalytics } from '@hooks/analytics';
import { calcPrice } from '@core/utils';
import * as api from '@core/api';
import { Bundle, Product } from '@interface/gatsby';
import { AnyObject, Coupon, CartItem, CartItemType, CartSummaryRow } from '@interface/common';


const byType = (items: CartItem[], type: CartItemType): CartItem[] => {
  return items?.filter((x) => x.type === type) || [];
};

const belongsToCoupon = (productId: string, coupon: Coupon | null): boolean => {
  return !!(coupon && !coupon.error && (!coupon.products || coupon.products?.includes(productId)));
};

const buildItem = (type: CartItemType, data: Product | Bundle): CartItem => {
  let item: any = {
    type,
    id: data.id,
    createdAt: new Date(),
  };
  if (type === ITEM.BUNDLE) {
    // @ts-ignore
    item.products = data.items.map(i => i.product.id);
    // @ts-ignore
    item.discount = data.discount;
  }
  return item;
};

export function useCart() {
  const captcha = useCaptcha();
  const dispatch = useBroadcastEmitter();
  const tracking = useAnalytics();
  const setCartItems = useSetRecoilState(cartItemsSelector);
  const resetCart = useResetRecoilState(cartAtom);
  const setCoupon = useSetRecoilState(couponSelector);
  const setProposals = useSetRecoilState(proposalsAtom);
  const resetProposals = useResetRecoilState(proposalsAtom);
  const isAuthenticated = useRecoilValueLoadable(isAuthenticatedSelector).valueMaybe();
  const cartItems = useRecoilValueLoadable(cartItemsSelector).valueMaybe();
  const coupon = useRecoilValueLoadable(couponSelector).valueMaybe();
  const purchases = useRecoilValueLoadable(purchasesSelector).valueMaybe();

  const updateRemoteCart = useRecoilCallback(({ snapshot }) => async () => {
    let isAuthenticated = await snapshot.getPromise(isAuthenticatedSelector);
    if (isAuthenticated) {
      let items = await snapshot.getPromise(cartItemsSelector);
      await api.updateCart(items);
    }
  }, []);

  function getCartData(overwrite: boolean = false) {
    return {
      couponToken: coupon?.token && !coupon.error ? coupon.token : null,
      items: cartItems,
      overwrite,
    };
  }

  function getItemsIds(type?: CartItemType) {
    return (type ? byType(cartItems, type) : cartItems).map((x: any) => x.id);
  }

  function addToCart(
    type: CartItemType,
    data: Product | Product[] | Bundle,
    broadcast = false,
  ) {
    if (isArray(data)) {
      const newData = data.filter((item) => !isInCart(type, item.id)).map(x => buildItem(type, x));
      if (newData.length > 0) {
        setCartItems((prevValue: any) => [...prevValue, ...newData]);
      }
    } else {
      if (!isInCart(type, data.id)) {
        setCartItems((prevValue: any) => [...prevValue, buildItem(type, data)]);
      }
    }

    if (broadcast) {
      updateRemoteCart();
      dispatch('addToCart', { type, data });
      tracking.addToCart(type, data);
    }
  }

  function removeFromCart(
    type: CartItemType,
    ids: string | string[],
    broadcast = false,
  ) {
    const check = (id: string) => isArray(ids) ? ids.includes(id) : id === ids;
    if (type === ITEM.PRODUCT && isLastInCart(ITEM.PRODUCT)) {
      revokeCoupon();
    }

    setCartItems((prevValue: any) => prevValue.filter((x: any) => {
      return check(x.id) ? x.type !== type : true;
    }));

    if (broadcast) {
      updateRemoteCart();
      dispatch('removeFromCart', { type, ids });
    }
  }

  function clearCart(broadcast = false) {
    resetCart();
    broadcast && dispatch('clearCart');
  }

  function isInCart(type: CartItemType, id: string) {
    return !!cartItems?.find((x: any) => x.type === type && x.id === id);
  }

  function isLastInCart(type: CartItemType | undefined = undefined) {
    return (isUndefined(type) ? cartItems : byType(cartItems, type))?.length === 1;
  }

  function isInBundle(id: string) {
    return byType(cartItems, ITEM.BUNDLE).some((x: any) => x.products?.includes(id));
  }

  function hasBundles() {
    return byType(cartItems, ITEM.BUNDLE).length > 0;
  }

  function hasDiscount(productId: string) {
    return belongsToCoupon(productId, coupon);
  }

  function getDiscount(productId: string) {
    return belongsToCoupon(productId, coupon) ? coupon!.discount : 0;
  }

  function anyItemHasDiscount() {
    return cartItems.some((item: any) => belongsToCoupon(item.id, coupon));
  }

  function applyCoupon(coupon: Coupon, broadcast = false) {
    const alreadyInCart = getItemsIds(ITEM.PRODUCT);
    const includedInBundles = byType(cartItems, ITEM.PRODUCT).reduce((acc: string[], item) => {
      return [...acc, ...(item.products || [])];
    }, []);
    const candidates = purchases ? difference(difference(coupon.products, purchases), alreadyInCart) : [];
    const proposals = candidates.filter(id => !includedInBundles.includes(id));

    setCoupon(coupon);
    setProposals({
      ids: proposals,
      modal: proposals.length > 0,
    });

    broadcast && dispatch('applyCoupon', { coupon });
  }

  function revokeCoupon(broadcast = false) {
    if (coupon?.token && broadcast) {
      if (!isAuthenticated) {
        if (captcha.execute) {
          captcha.execute('revoke_coupon').then((tokenV3) => {
            api.revokeCoupon(coupon.token, tokenV3);
          });
        }
      } else {
        api.revokeCoupon(coupon.token);
      }
      dispatch('revokeCoupon');
    }
    setCoupon(null);
  }

  function isCouponApplied() {
    return coupon !== null;
  }

  function isCouponValid() {
    return !coupon?.hasOwnProperty('error');
  }

  function closeProposalsModal() {
    setProposals((prevState: any) => ({ ...prevState, modal: false }));
  }

  function clearProposals() {
    resetProposals();
  }

  function calculateTotals(products: AnyObject<Product>, bundles: AnyObject<Bundle>) {
    const [originalAmount, totalAmount] = cartItems.reduce((acc: number[], cartItem: CartItem) => {
      let data, discount;
      if (cartItem.type === ITEM.PRODUCT) {
        data = products[cartItem.id];
        discount = belongsToCoupon(cartItem.id, coupon) ? coupon.discount : 0;
      } else {
        data = bundles[cartItem.id];
        discount = data.discount || 0;
      }
      acc[0] = acc[0] + data.price;
      acc[1] = acc[1] + calcPrice(data.price, discount);

      return acc;
    }, [0, 0]);

    const savedAmount = originalAmount - totalAmount;

    return { products, originalAmount, savedAmount, totalAmount };
  }

  return {
    getCartData,
    getItemsIds,
    addToCart,
    removeFromCart,
    clearCart,
    isInCart,
    isLastInCart,
    isInBundle,
    hasBundles,
    hasDiscount,
    getDiscount,
    anyItemHasDiscount,
    applyCoupon,
    revokeCoupon,
    isCouponApplied,
    isCouponValid,
    closeProposalsModal,
    clearProposals,
    calculateTotals,
  };
}

export function useMergeCartItems(products: AnyObject<Product>, bundles: AnyObject<Bundle>) {
  const items = useRecoilValue(cartItemsSelector);

  return React.useMemo<CartSummaryRow[]>(() => {
    return items.map((item: CartItem) => {
      if (item.type == ITEM.PRODUCT) {
        return { ...item, ...products[item.id] };
      } else {
        return { ...item, ...bundles[item.id], ids: item.products };
      }
    });
  }, [items]);
}
