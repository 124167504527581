import { EMPTY, SHORT, WEAK, MEDIUM, STRONG } from '../constants/password_diversity'
import { Options } from 'check-password-strength';

const PasswordDiversity: Options<string> = [
  {
    id: 0,
    value: EMPTY,
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: SHORT,
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: WEAK,
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 3,
    value: MEDIUM,
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 4,
    value: STRONG,
    minDiversity: 4,
    minLength: 8,
  },
];

export default PasswordDiversity;
