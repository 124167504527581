import * as React from 'react';
import { Link } from 'gatsby';
import { useRecoilValue } from 'recoil';
import { cartItemsCountSelector } from '@core/atoms';
import { useIsClient } from '@core/hooks';
import { useRoutes } from '@hooks/routes';
import CartIcon from '@assets/cart.svg';
import * as styles from './CartWidget.module.scss';


function Count() {
  const cartItemsCount = useRecoilValue(cartItemsCountSelector);
  if (cartItemsCount > 0) {
    return <span className={styles.count}>{cartItemsCount}</span>;
  }
  return null;
}

type Props = {
  onClick?: React.MouseEventHandler;
}

export default function CartWidget({ onClick }: Props) {
  const isClient = useIsClient();
  const { route, CART } = useRoutes();

  return (
    <Link
      className={styles.container}
      activeClassName={undefined}
      to={route(CART)}
      onClick={onClick}
      aria-label="Shopping cart"
    >
      {isClient && (
        <React.Suspense>
          <Count/>
        </React.Suspense>
      )}
      <CartIcon className={styles.icon}/>
    </Link>
  );
}
