import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export default function useHtmlDataset(name: string, value: string) {
  useIsomorphicLayoutEffect(() => {
    const html = document.documentElement;
    if (!html.dataset[name] || html.dataset[name] !== value) {
      html.dataset[name] = value;
    }

    return () => {
      delete html.dataset[name];
    };
  }, []);
};
