import { useRecoilState } from 'recoil';
import { alertAtom, alertStateDefaults } from '../atoms';
import { State } from '@interface/common';


type AlertOptions = Omit<State.Alert, 'isShown' | 'type' | 'onCancel'>
type ConfirmOptions = Omit<State.Alert, 'isShown' | 'type'>

export default function useAlert() {
  const [alert, setAlert] = useRecoilState(alertAtom);

  function showAlert(options: AlertOptions, onAccept?: State.Alert['onAccept']) {
    setAlert({
      ...alertStateDefaults,
      ...options,
      buttons: {
        ...alertStateDefaults.buttons,
        ...options.buttons,
      },
      isShown: true,
      type: 'alert',
      onAccept,
    });
  }

  function requestConfirmation(
    options: ConfirmOptions,
    onAccept?: State.Alert['onAccept'],
    onCancel?: State.Alert['onCancel'],
  ) {
    setAlert({
      ...alertStateDefaults,
      ...options,
      buttons: {
        ...alertStateDefaults.buttons,
        ...options.buttons,
      },
      isShown: true,
      type: 'confirm',
      onAccept,
      onCancel,
    });
  }

  function accept() {
    alert.onAccept?.();
    setAlert(prevState => ({ ...prevState, isShown: false }));
  }

  function cancel() {
    alert.onCancel?.();
    setAlert(prevState => ({ ...prevState, isShown: false }));
  }

  function reset() {
    setAlert(alertStateDefaults);
  }

  return {
    ...alert,
    showAlert,
    requestConfirmation,
    reset,
    accept,
    cancel,
  };
};
