import * as React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useUser } from '@hooks/user';
import { useRoutes } from '@hooks/routes';
import * as api from '@core/api';
import Link from '@components/Link';
import LibraryIcon from '@assets/library.svg';
import PurchasesIcon from '@assets/clipboard.svg';
import ProfileIcon from '@assets/contact.svg';
import SecurityIcon from '@assets/shield.svg';
import NewsletterIcon from '@assets/radio.svg';
import LogOutIcon from '@assets/log-out.svg';
import * as styles from './AccountMenu.module.scss';


type Props = {
  activeClassName?: string;
  onItemClick?: () => void;
}

export default function AccountMenu({ activeClassName, onItemClick }: Props) {
  const location = useLocation();
  const { signOut } = useUser();
  const { route, routes, ACCOUNT, ORDERS_HISTORY, PROFILE, SECURITY, NEWSLETTER } = useRoutes();

  const handleLogOut = React.useCallback(async (event: React.SyntheticEvent): Promise<any> => {
    event.preventDefault();
    onItemClick?.();

    if (routes.private.includes(location.pathname)) {
      await navigate('/');
    }

    signOut(true);

    try {
      await api.logOut();
    } catch (error) {}
  }, [routes.private, location.pathname, onItemClick]);

  const props = {
    activeClassName,
    onClick: onItemClick,
  };

  return (
    <>
      <Link {...props} to={route(ACCOUNT)} >
        <LibraryIcon className={styles.icon}/>
        <span>Library</span>
      </Link>
      <Link {...props} to={route(ORDERS_HISTORY)}>
        <PurchasesIcon className={styles.icon}/>
        <span>Purchases</span>
      </Link>
      <Link {...props} to={route(PROFILE)}>
        <ProfileIcon className={styles.icon}/>
        <span>Profile</span>
      </Link>
      <Link {...props} to={route(SECURITY)}>
        <SecurityIcon className={styles.icon}/>
        <span>Security</span>
      </Link>
      <Link {...props} to={route(NEWSLETTER)}>
        <NewsletterIcon className={styles.icon}/>
        <span>Newsletter</span>
      </Link>
      <a href="#" onClick={handleLogOut}>
        <LogOutIcon className={styles.icon}/>
        <span>Log out</span>
      </a>
    </>
  );
}
