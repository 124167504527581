import * as React from 'react';
import { useEventListener } from '@core/hooks';
import { isBrowser } from '@core/utils/env';
import { addCssCustomProperty } from '@core/utils';
import useThrottle from './useThrottle';


export default function useModalOpenFix() {
  if (!isBrowser || typeof document === 'undefined') {
    return;
  }

  const calculate = () => {
    let body = document.querySelector('body');
    if (body) {
      let scrollbarWidth = window.innerWidth - body.clientWidth;
      addCssCustomProperty('--scrollbar-width', `${scrollbarWidth}px`);
    }
  };

  React.useEffect(() => {
    calculate();
  }, []);

  const throttledCalculateHandler = useThrottle(calculate);

  useEventListener('resize', throttledCalculateHandler);
};
