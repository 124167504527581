import * as React from 'react';
import { clsx } from 'clsx';
import { isEqual } from 'lodash';
import AvatarIcon from '@assets/avatar.svg';
import * as styles from './Avatar.module.scss';


type Props = {
  className?: string;
};

function Avatar({ className }: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      <AvatarIcon className={styles.icon}/>
    </div>
  );
}

export default React.memo(Avatar, ((prev, next) => {
  return isEqual(prev.className, next.className);
})) as typeof Avatar;
